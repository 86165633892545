
import { mapGetters } from "vuex"
export default {
	data() {
		return {
			loading: true,
		}
	},
	async fetch() {
		/* TODO: Fetch and save to store rather than bind realtime */
		await this.$store.dispatch("globals/bindSettings")
		await this.$store.dispatch("classes/bindIndex")
		await this.$store.dispatch("trades/bindIndex")
		await this.$store.dispatch("ipt/bindIndex")
		await this.$store.dispatch("entities/bindIndex")
		await this.$store.dispatch("users/bindIndex")
		this.loading = false
	},
	computed: {
		...mapGetters({
			user: "user",
			isBroker: "isBroker",
		}),
	},
	middleware: ["isAuthenticated"],
}
