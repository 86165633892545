import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		index: [],
		current: {},
	}
}

export const state = () => ({
	index: [],
	current: {},
})

export const mutations = {
	...vuexfireMutations,
	SET_INDEX(state, docs) {
		state.index = docs
	},
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bind: firestoreAction(async function ({ bindFirestoreRef }, id) {
		const ref = this.$fire.firestore.collection("classes").doc(id)
		await bindFirestoreRef("current", ref, { wait: true })
	}),
	unbind: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("current", () => {
			return []
		})
	}),
	async bindIndex({ commit }) {
		const ref = this.$fire.firestore.collection("classes").orderBy("title", "asc")
		ref.onSnapshot((snapshot) => {
			const docs = snapshot.docs.map((doc) => ({
				id: doc.id,
				title: doc.data().title,
			}))
			commit("SET_INDEX", docs) // Always use commit to change state
		})
	},
	reset({ commit, dispatch }) {
		dispatch("unbindIndex")
		dispatch("unbind")
		commit("RESET_STATE")
	},
}

export const getters = {
	getIndex(state) {
		return state.index
	},
	getCurrent(state) {
		return state.current
	},
	getLabelledIndex(state) {
		const transformed = state.index.map(({ title, id }) => ({
			label: title,
			value: id,
		}))
		return transformed
	},
}
