import { render, staticRenderFns } from "./portal.vue?vue&type=template&id=20a81632&scoped=true&"
import script from "./portal.vue?vue&type=script&lang=js&"
export * from "./portal.vue?vue&type=script&lang=js&"
import style0 from "./portal.vue?vue&type=style&index=0&id=20a81632&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a81632",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDrawer: require('/Users/azeem/Projects/stratify-beta-nuxt2-firebase/components/app/Drawer.vue').default,AppHeader: require('/Users/azeem/Projects/stratify-beta-nuxt2-firebase/components/app/Header.vue').default,Spinner: require('/Users/azeem/Projects/stratify-beta-nuxt2-firebase/components/Spinner.vue').default})
